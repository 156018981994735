html {
  overscroll-behavior: none;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
    overscroll-behavior-y: contain;
}

/* prevent pull-to-refresh for Safari 16+ */
@media screen and (pointer: coarse) {
  @supports (-webkit-backdrop-filter: blur(1px)) and (overscroll-behavior-y: none)  {
    html {
      min-height: 100.3%;
      overscroll-behavior-y: none;
    }
  }
}
/* prevent pull-to-refresh for Safari 9~15 */
@media screen and (pointer: coarse) {
  @supports (-webkit-backdrop-filter: blur(1px)) and (not (overscroll-behavior-y: none))  {
    html {
      height: 100%;
      overflow: hidden;
    }
    body {
      margin: 0px;
      max-height: 100%; /* or `height: calc(100% - 16px);` if body has default margin */
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
    /* in this case to disable pinch-zoom, set `touch-action: pan-x pan-y;` on `body` instead of `html` */
  }
}

/* prevent pull-to-refresh for Chrome 63+ */
body{
  overscroll-behavior-y: none;
}